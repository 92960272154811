import React from 'react';
import Navbar from './components/Navbar';
// import Navbar from './components/nav2.0';
import Hero from './components/Hero';
import About from './components/About';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import Footer from './components/Footer';
import WhyChoose from './components/ChoseDIGIDIT';
import Testimonials from './components/Testimonial';
import 'aos/dist/aos.css';
import ButtonGrid from './components/buttongrid';

const App = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-yellow-50 via-pink-50 to-blue-100">
      <Navbar />
      <Hero />
      <Portfolio />
      {/* <ButtonGrid /> */}
      <WhyChoose />
      <About />
      <Testimonials />
      <Contact />
      <Footer />
      {/* set NODE_OPTIONS=--openssl-legacy-provider && npm start */}
    </div>
  );
};

export default App;
