import React from "react";
import Slider from "react-slick";

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
  };

  const testimonials = [
    {
      quote:
        "Suraj is an exceptional video editor, always incredibly responsive and accommodating even with last-minute requests, consistently delivering high-quality work exactly as envisioned. I am so grateful to have him producing our Gen She Podcast.",
      name: "Avni Barman",
      position: "Founder of Gen She",
    },
    {
      quote:
        "Suraj is the most hardworking editor I have worked with. Super cooperative and polite. He has never sacrificed the quality in any video. Happy working with him.",
      name: "Vansh Gujral",
      position: "Content Strategist At TRS Clips",
    },
  ];

  return (
    <section id="testimonials" className="py-10 w-full">
      <div className="container mx-auto text-center px-4 max-w-4xl">
        <h2 className="text-3xl font-bold mb-8">Clients Feedback</h2>
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="p-4 flex flex-col items-center"
              style={{ minHeight: "200px" }}
            >
              <p className="text-base mb-4">"{testimonial.quote}"</p>
              <div>
                <h4 className="text-lg font-bold">{testimonial.name}</h4>
                <p className="text-sm">{testimonial.position}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
