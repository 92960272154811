import React, { useState } from 'react';
import 'aos/dist/aos.css';

const Navbar = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const handleDropdownClick = () => {
    setDropdownVisible(!dropdownVisible);
    console.log('Dropdown Visible:', !dropdownVisible); // Printing the dropdown state
  };

  return (
    <nav className="bg-black text-white py-3 fixed w-full z-10 top-0 left-0">
      <div className="container-fluid mx-auto flex justify-between items-center px-6">
        <div className="container-fluid mx-auto flex">
          <a href="#Hero" className="hover:text-yellow-400 px-4 ">
            <img
              src={`${process.env.PUBLIC_URL}/logo.PNG.png`}
              alt="Logo"
              className="h-10 w-auto"
            />
          </a>
          <h1 className="text-3xl font-bold">
            <a href="#Hero" className="hover:text-yellow-400 px-3 ">Digidit</a>
          </h1>
        </div>

        {/* Desktop Navigation */}
        <ul className="hidden md:flex space-x-6">
          <li className="relative">
            <a
              href="#services"
              className="hover:text-yellow-400 cursor-pointer"
              onClick={handleDropdownClick} // Added the click handler here
            >
              Services
            </a>

            {/* Dropdown Menu */}
            {dropdownVisible && (
              <ul className="absolute bg-black text-white shadow-lg rounded-md mt-2 z-50">
                <li className="px-4 py-2">
                  <a href="#video-editing" className="hover:text-yellow-400">
                    Video Editing
                  </a>
                </li>
                <li className="px-4 py-2">
                  <a href="#digital-marketing" className="hover:text-yellow-400">
                    Digital Marketing
                  </a>
                </li>
                <li className="px-4 py-2">
                  <a href="#graphic-design" className="hover:text-yellow-400">
                    Graphic Design
                  </a>
                </li>
                <li className="px-4 py-2">
                  <a href="#web-development" className="hover:text-yellow-400">
                    Web Development
                  </a>
                </li>
              </ul>
            )}
          </li>
          <li><a href="#portfolio" className="hover:text-yellow-400">Portfolio</a></li>
          <li><a href="#about" className="hover:text-yellow-400">About</a></li>
          <li><a href="#contact" className="hover:text-yellow-400">Contact</a></li>
        </ul>

        {/* Mobile Hamburger Icon */}
        <div className="md:hidden flex items-center">
          <button
            onClick={() => setSidebarVisible(!sidebarVisible)}
            
            className="text-white focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Sidebar */}
      {sidebarVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-70 z-40">
          <div className="flex justify-end p-4">
            <button
              onClick={() => setSidebarVisible(false)}
              className="text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>  
          <div className="flex flex-col items-center text-white space-y-4 py-6">
            <a href="#Hero" className="hover:text-yellow-400">Video Editing</a>
            <a href="#Hero" className="hover:text-yellow-400">Digital Marketing</a>
            <a href="#Hero" className="hover:text-yellow-400">Graphic Design</a>
            <a href="#Hero" className="hover:text-yellow-400">Web Development</a>
            <a href="#portfolio" className="hover:text-yellow-400">Portfolio</a>
            <a href="#about" className="hover:text-yellow-400">About</a>
            <a href="#contact" className="hover:text-yellow-400">Contact</a>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
