import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Hero = () => {
  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 800,
      easing: 'ease-in-out',
      once: true,
      offset: 100,
    });

    // Scroll to top on reload
    window.scrollTo(0, 0);
  }, []);

  return (
    <section
      id="Hero"
      className="h-screen flex items-center justify-center bg-cover bg-center relative pt-28"
      style={{
        // backgroundImage: `url('/path-to-hero-image.jpg')`,
        backgroundAttachment: 'fixed',
      }}
       
    >
      <div className="absolute inset-0 opacity-50"></div>
      <div className="text-center text-black relative z-8">
        <div className="flex items-center justify-center">
        <img
            src={`${process.env.PUBLIC_URL}/logo_dark.png`}
            alt="Logo"
            className="h-40 w-auto"
            style={{ willChange: 'transform' }}
             
          />

        </div>
        <h1 className="text-5xl font-bold mb-4"  >
          Welcome to Digidit
        </h1>
        <p className="text-xl mb-6 py-6 px-10"  >
          Video editing services for creators and brands. <br />
          Whether it’s reels, YouTube videos, or business promos, we ensure quality and timely delivery.
        </p>
        <button
          className="px-6 py-3 bg-yellow-400 text-gray-900 font-bold rounded-lg hover:bg-yellow-500 transition-transform transform"
          onClick={() =>
            document.getElementById('portfolio').scrollIntoView({ behavior: 'smooth' })
          }
        >
          Explore My Work
        </button>
      </div>
    </section>
  );
};

export default Hero;
